import React, { useState, useCallback, MouseEvent, useContext } from 'react'
import { CollapseContent } from './CollapseContent'
import { StyledCollapseHeader, CollapseWrapper } from './styled'
import { Heading, Icon } from '@components'
import { ThemeContext } from 'styled-components'

type CollapseProps = {
  title: string
  id: string
}

export const Collapse: React.FC<CollapseProps> = ({ id, title, children }) => {
  const [isVisible, setVisible] = useState(false)
  const { colors } = useContext(ThemeContext)

  const handleCollapse = useCallback((event: MouseEvent) => {
    event.preventDefault()
    setVisible(prevState => !prevState)
  }, [])

  return (
    <CollapseWrapper>
      <StyledCollapseHeader collapsed={!isVisible}>
        <Heading variant="h4" textSize="xlarge" id={`heading${id}`}>
          <a href={`#${id}`} onClick={handleCollapse} aria-expanded={isVisible} aria-controls={id}>
            <span>{title}</span>
            <Icon
              name="ArrowDown"
              width={19}
              height={10}
              className={isVisible ? 'opened' : ''}
              color={colors.gray700}
            />
          </a>
        </Heading>
      </StyledCollapseHeader>
      <CollapseContent isOpen={isVisible} id={id} aria-labelledby={`heading${id}`}>
        {children}
      </CollapseContent>
    </CollapseWrapper>
  )
}
