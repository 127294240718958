import React, { forwardRef } from 'react'
import { StyledBox, BoxProps } from './styled'

export const Box: React.FC<BoxProps> = forwardRef(
  ({ children, ...rest }, ref: React.Ref<HTMLDivElement>) => (
    <StyledBox ref={ref} {...rest}>
      {children}
    </StyledBox>
  ),
)

Box.displayName = 'Box'
