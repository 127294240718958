import { isClient } from '@utils'
import React, { useContext, useEffect, useRef } from 'react'
// import { Image } from '@components'
import { useLabels } from '@hooks'
import { ThemeContext } from 'styled-components'
import { BeerImgContainer, StyledBeforeAfterImg } from './styled'
import { Heading } from '@components'
import { Row, Col } from '@bootstrap-styled/v4'

import 'beerslider/dist/BeerSlider.css'
import { TextAlignment } from '@types'

type Props = {
  title: string
  content: string | null
  urlBefore: string
  urlAfter: string
}

export const BeforeAfter: React.FC<Props> = ({ title, content, urlBefore, urlAfter }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const { transitionDurations } = useContext(ThemeContext)
  const labels = useLabels()
  // const imageBefore = getFileName(urlBefore)
  // const imageAfter = getFileName(urlAfter)

  // if (imageBefore && imageAfter) {
  //   return <Image name={imageBefore} />
  // }

  useEffect(() => {
    if (containerRef.current && isClient) {
      // @ts-ignore
      window.BeerSlider && new window.BeerSlider(containerRef.current)
    }
  }, [])

  return (
    <div data-sal="slide-up" data-sal-duration={transitionDurations.sal}>
      <div className="text-center">
        <Heading variant="h2" className="mb-4 d-inline-block" align={TextAlignment.CENTER}>
          {title}
        </Heading>
      </div>
      {content && <div dangerouslySetInnerHTML={{ __html: content }} className="clearfix" />}
      <Row className="justify-content-center">
        <Col xs={12} lg={10} xl={8}>
          <BeerImgContainer
            ref={containerRef}
            className="beer-slider mb-5"
            data-beer-label={labels.after}
          >
            <StyledBeforeAfterImg src={urlAfter} alt="" loading="lazy" />
            <BeerImgContainer className="beer-reveal" data-beer-label={labels.before}>
              <StyledBeforeAfterImg src={urlBefore} alt="" loading="lazy" />
            </BeerImgContainer>
          </BeerImgContainer>
        </Col>
      </Row>
    </div>
  )
}
