export const getFileName = (url: string | undefined): string | null => {
  if (!url) {
    return null
  }

  const chunks = url.split('/')

  if (!chunks.length) {
    return null
  }

  return chunks[chunks.length - 1]
}
