type JsonResponse = {
  [index: string]: any
}

const headers = {
  Accept: 'application/json',
}

const checkResponse = (response: Response): Response => {
  if (response.ok) {
    return response
  }

  throw new Error(`${response.status} ${response.statusText}`)
}

const parseJSON = async (response: Response): Promise<JsonResponse> => {
  return await response.json()
}

export const simpleFetch = {
  /**
   * Sends a POST request to given URL.
   * @param url - string
   * @param params - object contains parameters for the request
   * @returns Promise with <JsonResponse> shape
   */
  post: (url: RequestInfo, data: FormData): Promise<JsonResponse> => {
    return fetch(url, {
      method: 'POST',
      headers,
      body: data,
    })
      .then(checkResponse)
      .then(parseJSON)
  },
}
