import { useStaticQuery, graphql } from 'gatsby'

type Benefit = {
  id: number
  title: string
  description: string
  icon: string
}

export const useBenefits = (): Benefit[] => {
  const data = useStaticQuery(graphql`
    query BenefitsQuery {
      wpgraphql {
        benefits(where: { orderby: { order: ASC, field: DATE } }) {
          nodes {
            databaseId
            title
            acf {
              description
              icon
            }
          }
        }
      }
    }
  `)

  return data.wpgraphql.benefits.nodes.map((benefit: any) => ({
    id: benefit.databaseId,
    title: benefit.title,
    description: benefit.acf.description,
    icon: benefit.acf.icon,
  }))
}
