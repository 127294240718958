import React, { useContext } from 'react'
import ReactModal, { Styles } from 'react-modal'
import { ThemeContext } from 'styled-components'

ReactModal.setAppElement('#___gatsby')

type ModalProps = {
  /** Boolean describing if the modal should be shown or not. */
  isOpen: boolean
  /** String / ID of heading that is associated with the modal. */
  labelledby: string
  /** Boolean indicating if the overlay should close the modal. */
  shouldCloseOnOverlayClick?: boolean
  /** Boolean indicating if pressing the esc key should close the modal. */
  shouldCloseOnEsc?: boolean
  /** Function that will be run after the modal has opened. */
  onAfterOpen?: () => void
  /** Function that will be run after the modal has closed. */
  onAfterClose?: () => void
  /** Function that will be run when the modal is requested to be closed. */
  onRequestClose?: () => void
}

const modalStyle: Styles = {
  overlay: {
    zIndex: 9001,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    overflowY: 'auto',
  },
  content: {
    background: 'transparent',
    minWidth: '300px',
    width: '100%',
    maxWidth: '960px',
    borderRadius: 0,
    border: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '0 15px 40px',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
  },
}

export const Modal: React.FC<ModalProps> = props => {
  const theme = useContext(ThemeContext)

  return (
    <ReactModal
      closeTimeoutMS={theme.transitionDurations.defaultNumber}
      style={modalStyle}
      aria={{ labelledby: props.labelledby }}
      {...props}
    >
      {props.children}
    </ReactModal>
  )
}

Modal.defaultProps = {
  shouldCloseOnOverlayClick: true,
  shouldCloseOnEsc: true,
}
