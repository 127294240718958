import { useStaticQuery, graphql } from 'gatsby'
import { Service } from '@types'

type ServiceNode = {
  databaseId: number
  title: string
  content: string | null
  uri: string
  acf: {
    shortdescription: string
  }
}

export const useServices = (): Service[] => {
  const data = useStaticQuery(graphql`
    query ServicesQuery {
      wpgraphql {
        services(where: { orderby: { field: DATE, order: ASC } }) {
          nodes {
            databaseId
            title
            content(format: RENDERED)
            uri
            acf {
              shortdescription
            }
          }
        }
      }
    }
  `)

  return data.wpgraphql.services.nodes.map(
    (service: ServiceNode): Service => ({
      id: service.databaseId,
      title: service.title,
      content: service.content,
      shortDescription: service.acf.shortdescription,
      url: service.uri,
    }),
  )
}
