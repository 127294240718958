import React from 'react'
import { Container } from '@bootstrap-styled/v4'
import { Copyright } from './styled'
import { useLabels, useSiteMetadata } from '@hooks'
import { graphql, useStaticQuery } from 'gatsby'

type FooterProps = {
  siteTitle: string
}

export const Footer: React.FC<FooterProps> = ({ siteTitle }) => {
  const labels = useLabels()
  const { author } = useSiteMetadata()

  // get GDPR page data
  const {
    wpgraphql: { page },
  } = useStaticQuery(graphql`
    query {
      wpgraphql {
        page(id: "3", idType: DATABASE_ID) {
          slug
          title(format: RENDERED)
          uri
          databaseId
          content(format: RENDERED)
        }
      }
    }
  `)

  return (
    <footer>
      <Container>
        <Copyright>
          &copy; {new Date().getFullYear()} {siteTitle} <span aria-hidden="true">|</span>{' '}
          <a href={page.uri}>{page.title}</a>
          <br />
          {labels.createdBy}{' '}
          <a href="https://radeksukup.com" target="_blank" rel="noopener noreferrer">
            {author}
          </a>
        </Copyright>
      </Container>
    </footer>
  )
}
