import React from 'react'
import styled, { css } from 'styled-components'

type ModalContenProps = {
  children?: React.ReactNode
}

const StyledModalContent = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => css`
    1px ${theme.spacer(1.5)} ${theme.spacer(1.5)}
  `};
  border-bottom-left-radius: ${({ theme }) => theme.borderRadius.large};
  border-bottom-right-radius: ${({ theme }) => theme.borderRadius.large};

  > div > *:first-child {
    margin-top: 0;
  }
`

export const ModalContent: React.FC<ModalContenProps> = ({ children }) => (
  <StyledModalContent>{children}</StyledModalContent>
)
