import { useStaticQuery, graphql } from 'gatsby'

type Contact = {
  email: string
  phoneNumber: string
  address: string
  officeHours: string
}

export const useContact = (): Contact => {
  const {
    wpgraphql: { page },
  } = useStaticQuery(graphql`
    query ContactQuery {
      wpgraphql {
        page(id: "19", idType: DATABASE_ID) {
          content(format: RENDERED)
          acf {
            email
            phonenumber
            address
            officehours
          }
        }
      }
    }
  `)

  return {
    email: page.acf.email,
    phoneNumber: page.acf.phonenumber,
    address: page.acf.address,
    officeHours: page.acf.officehours,
  }
}
