import styled from 'styled-components'
import { Button } from '@components'

export const GlideWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacer(2)};
`

export const GlideArrowButton = styled(Button)`
  background: transparent;
  margin: 0;
  box-shadow: none;
  border-radius: 0;

  &.btn {
    display: none;

    ${({ theme }) => theme.mediaQuery(theme.breakpoints.XSMALL, 'min')} {
      display: block;
    }
  }

  &.glide__arrow--left {
    left: -90px;
  }

  &.glide__arrow--right {
    right: -90px;
  }
`

export const ReferenceContent = styled.blockquote`
  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: 1.7;

  > *:last-child {
    margin-bottom: 0;
  }

  p {
    &:first-child {
      &::before {
        content: open-quote;
      }
    }
    &:last-child {
      &::after {
        content: close-quote;
      }
    }
  }
`

export const GlideBullets = styled.div.attrs({
  className: 'glide__bullets',
})`
  bottom: ${({ theme }) => theme.spacer(-1.5)};
`

export const GlideBullet = styled.button.attrs({
  className: 'glide__bullet',
})`
  width: 10px;
  height: 10px;
  margin: 0 ${({ theme }) => theme.spacer(0.5)};
  border: 0;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: none;
  ${({ theme }) => theme.transition('all')};

  &.glide__bullet--active {
    background-color: ${({ theme }) => theme.colors.black};
  }

  &:hover,
  &:focus {
    border: 0;
  }
`
