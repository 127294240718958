import React from 'react'
import { LoaderWrapper, LoaderBackdrop } from './styled'

type Props = {
  isVisible: boolean
}

export const Loader: React.FC<Props> = ({ isVisible }) => (
  <>
    <LoaderBackdrop isVisible={isVisible} />
    {isVisible && <LoaderWrapper />}
  </>
)
