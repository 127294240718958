import { useStaticQuery, graphql } from 'gatsby'
import { Reference } from '@types'

export const useReferences = (): Reference[] => {
  const data = useStaticQuery(graphql`
    query ReferencesQuery {
      wpgraphql {
        references(where: { orderby: { field: MODIFIED, order: DESC } }, first: 100) {
          nodes {
            id
            title
            content
          }
        }
      }
    }
  `)

  return data.wpgraphql.references.nodes.map((ref: any) => ({
    id: ref.id,
    name: ref.title,
    content: ref.content,
  }))
}
