import React from 'react'
import { Helmet } from 'react-helmet'
import { useSiteMetadata } from '@hooks'

import shareImage from '@images/share.png'
import BarlowRegular from '@fonts/Barlow-Regular.woff2'
import BarlowMedium from '@fonts/Barlow-Medium.woff2'
import BarlowBold from '@fonts/Barlow-Bold.woff2'

type SEO = {
  lang?: string
  title: string
  pageUrl: string
}

export const SEO: React.FC<SEO> = ({ title, pageUrl }) => {
  const site = useSiteMetadata()

  return (
    <Helmet
      htmlAttributes={{
        lang: 'cs',
      }}
      title={title}
      titleTemplate={`%s | ${site.title}`}
      meta={[
        {
          name: 'description',
          content: site.description,
        },
        {
          name: 'keywords',
          content: site.keywords,
        },
        {
          name: 'author',
          content: site.author,
        },
        {
          property: 'og:title',
          content: `${title} | ${site.title}`,
        },
        {
          property: 'og:site_name',
          content: site.title,
        },
        {
          property: 'og:description',
          content: site.description,
        },
        {
          property: 'og:type',
          content: `website`,
        },
        {
          property: 'og:url',
          content: `${site.url}${pageUrl}`,
        },
        {
          property: 'og:image',
          content: shareImage,
        },
        {
          property: 'og:image:type',
          content: 'image/png',
        },
        {
          property: 'og:image:width',
          content: '884',
        },
        {
          property: 'og:image:height',
          content: '406',
        },
        {
          name: 'twitter:card',
          content: `summary`,
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: site.description,
        },
        {
          name: 'twitter:image',
          content: shareImage,
        },
        {
          name: 'google-site-verification',
          content: site.googleSiteVerificationKey,
        },
      ]}
    >
      <link
        rel="preload"
        href={BarlowRegular}
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link rel="preload" href={BarlowMedium} as="font" type="font/woff2" crossOrigin="anonymous" />
      <link rel="preload" href={BarlowBold} as="font" type="font/woff2" crossOrigin="anonymous" />
    </Helmet>
  )
}
