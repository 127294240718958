import styled, { keyframes } from 'styled-components'

const floatingArrow = keyframes`
  0%,
  100% {
    transform: translate(-50%, 0);
  }

  50% {
    transform: translate(-50%, 5px);
  }
`

export const StyledScrollDownArrow = styled.a`
  padding: ${({ theme }) => theme.spacer(0.5)} ${({ theme }) => theme.spacer(1)};
  line-height: 0;
  position: absolute;
  left: 50%;
  bottom: ${({ theme }) => theme.spacer(0.5)};
  z-index: 0;
  transform: translateX(-50%);
  animation: 2s ${floatingArrow} infinite;
`
