import React from 'react'
import styled from 'styled-components'

type ModalHeaderProps = {
  /** String / ID for heading will be associated with the modal. */
  id: string
  /** Title of the modal. */
  title: string
}

const StyledModalHeader = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => theme.spacer(1.5)};
  position: relative;
  ${({ theme }) => theme.mediaQuery(theme.breakpoints.XSMALL, 'max')} {
    padding-right: ${({ theme }) => theme.spacer(3)};
  }
  border-top-left-radius: ${({ theme }) => theme.borderRadius.large};
  border-top-right-radius: ${({ theme }) => theme.borderRadius.large};
`

const StyledModalTitle = styled.h5`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSizes.xxlarge};
  margin: 0;
`

export const ModalHeader: React.FC<ModalHeaderProps> = ({ id, title, children }) => (
  <StyledModalHeader>
    <StyledModalTitle id={id}>{title}</StyledModalTitle>
    {children}
  </StyledModalHeader>
)
