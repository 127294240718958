import styled from 'styled-components'
import { Button } from '@bootstrap-styled/v4'

const barWidth = '32px'
const barHeight = '3px'

export const HamburgerWrapper = styled(Button)`
  ${({ theme }) => theme.mediaQuery(theme.breakpoints.MEDIUM, 'min')} {
    display: none !important;
  }

  background-color: ${({ theme }) => theme.colors.white} !important;
  padding: ${({ theme }) => theme.spacer(0.5)} !important;
  line-height: 0 !important;
  overflow: hidden;

  ${({ theme }) => theme.mediaQuery(theme.breakpoints.MEDIUM, 'max')} {
    position: relative;
    z-index: 1001;
  }
`

export const HamburgerBox = styled.span.attrs({
  className: 'hamburger-box',
  'aria-hidden': 'true',
})`
  width: ${barWidth};
`

export const HamburgerInner = styled.span.attrs({
  className: 'hamburger-inner',
})`
  &,
  &::before,
  &::after {
    width: ${barWidth};
    height: ${barHeight};
    border-radius: 0;
  }
`
