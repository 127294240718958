import { PageProps } from 'gatsby'

export type MenuItem = {
  id: string
  label: string
  url: string
}

export type ACF<T> = {
  acf: T
}

export type Page<T> = PageProps & {
  data: {
    wpgraphql: {
      page: Partial<T> & {
        title: string
        content: string
        isFrontPage: boolean
        slug: string
        uri: string
      }
    }
  }
}

export type Service = {
  id: number
  title: string
  content: string | null
  shortDescription: string
  url: string
}

export type Reference = {
  id: string
  name: string
  content: string
}

export enum TextAlignment {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}
