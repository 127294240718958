import { useStaticQuery, graphql } from 'gatsby'

type SiteMetadata = {
  title: string
  description: string
  url: string
  owner: string
  contactFormEndpoint: string
  recaptchaSitekey: string
  keywords: string
  author: string
  facebookUrl: string
  instagramUrl: string
  googleSiteVerificationKey: string
}

export const useSiteMetadata = (): SiteMetadata => {
  const { wpgraphql, site } = useStaticQuery(graphql`
    query SiteMetadataQuery {
      wpgraphql {
        allSettings {
          generalSettingsTitle
          generalSettingsDescription
        }
      }
      site {
        siteMetadata {
          owner
          url
          contactFormEndpoint
          recaptchaSitekey
          keywords
          author
          facebookUrl
          instagramUrl
          googleSiteVerificationKey
        }
      }
    }
  `)

  return {
    title: wpgraphql.allSettings.generalSettingsTitle,
    description: wpgraphql.allSettings.generalSettingsDescription,
    url: site.siteMetadata.url,
    owner: site.siteMetadata.owner,
    contactFormEndpoint: site.siteMetadata.contactFormEndpoint,
    recaptchaSitekey: site.siteMetadata.recaptchaSitekey,
    keywords: site.siteMetadata.keywords,
    author: site.siteMetadata.author,
    facebookUrl: site.siteMetadata.facebookUrl,
    instagramUrl: site.siteMetadata.instagramUrl,
    googleSiteVerificationKey: site.siteMetadata.googleSiteVerificationKey,
  }
}
