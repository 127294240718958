import styled from 'styled-components'
import { Table } from '@bootstrap-styled/v4'

export const TablePriceList = styled(Table)`
  &.table {
    tr {
      th,
      td {
        vertical-align: middle;
      }
    }

    ${({ theme }) => theme.mediaQuery(theme.breakpoints.MEDIUM, 'max')} {
      tr {
        display: flex;
        flex-wrap: wrap;
      }
      th {
        flex-basis: 100%;
      }
      td {
        flex-basis: 50%;
        border-top: 0;
      }
    }
    ${({ theme }) => theme.mediaQuery(theme.breakpoints.MEDIUM, 'min')} {
      td,
      th {
        padding: ${({ theme }) => theme.spacer(1.5)} ${({ theme }) => theme.spacer(1)};
      }
      th {
        width: 60%;
      }
    }
  }

  &.table-hover {
    tr {
      ${({ theme }) => theme.transition('background-color')}
    }
  }
`
