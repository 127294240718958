import styled, { css } from 'styled-components'
import bg from '@images/bg-wall.png'

type SectionProps = {
  fullPage?: boolean
  hasBg?: boolean
  isFirst?: boolean
  delimiter?: boolean
  verticalPadding?: boolean
  vCenter?: boolean
}

const headerHeight = '184px'
const headerHeightMobile = '144px'

export const Section = styled.section<SectionProps>`
  padding: 1px 0;
  /* min-height: 50vh; */

  ${({ vCenter }) =>
    vCenter &&
    css`
      display: flex;
      align-items: center;
    `}

  ${({ fullPage, isFirst, theme }) =>
    fullPage &&
    css`
      min-height: 100vh;
      ${isFirst &&
      css`
        min-height: calc(100vh - ${headerHeightMobile});
        position: relative;
        padding-bottom: ${theme.spacer(2)};
        ${theme.mediaQuery(theme.breakpoints.MEDIUM, 'min')} {
          min-height: calc(100vh - ${headerHeight});
        }
      `}
    `}

  ${({ hasBg }) =>
    hasBg &&
    css`
      background-image: url(${bg});
      background-attachment: fixed;
    `}

  ${({ delimiter }) =>
    delimiter &&
    css`
      min-height: 0;
      height: 160px;
    `}

  ${({ verticalPadding, theme }) =>
    verticalPadding &&
    css`
      padding: ${theme.spacer(3)} 0;
    `}
`
