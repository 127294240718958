import { useStaticQuery, graphql } from 'gatsby'

type Benefit = {
  id: number
  question: string
  answer: string
}

export const useFAQs = (): Benefit[] => {
  const data = useStaticQuery(graphql`
    query FAQsQuery {
      wpgraphql {
        faqs(where: { orderby: { order: ASC, field: DATE } }) {
          nodes {
            databaseId
            title
            content
          }
        }
      }
    }
  `)

  return data.wpgraphql.faqs.nodes.map((item: any) => ({
    id: item.databaseId,
    question: item.title,
    answer: item.content,
  }))
}
