import styled from 'styled-components'

export const StyledBeforeAfterImg = styled.img`
  height: inherit !important;
  object-fit: cover;
`

export const BeerImgContainer = styled.div`
  &::after {
    text-transform: uppercase;
  }
`
