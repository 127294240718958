import React from 'react'
import { useLabels } from '@hooks'
import { HamburgerWrapper, HamburgerBox, HamburgerInner } from './styled'

type HamburgerProps = {
  isActive: boolean
  onClick: () => void
}

export const Hamburger: React.FC<HamburgerProps> = ({ isActive, onClick }) => {
  const { showNavigation, hideNavigation } = useLabels()

  return (
    <HamburgerWrapper
      size="sm"
      outline
      className={`hamburger hamburger--squeeze ${isActive ? 'is-active' : ''}`}
      type="button"
      aria-controls="nav"
      aria-expanded={isActive}
      aria-label={isActive ? hideNavigation : showNavigation}
      onClick={onClick}
    >
      <HamburgerBox>
        <HamburgerInner />
      </HamburgerBox>
    </HamburgerWrapper>
  )
}
