import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

type ImageProps = {
  name: string
}

type GraphQLImages = {
  allImageSharp: {
    edges: Array<{
      node: {
        fluid: {
          aspectRatio: number
          base64: string
          originalName: string
          size: string
          src: string
          srcSet: string
        }
      }
    }>
  }
}

export const Image: React.FC<ImageProps> = ({ name }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allImageSharp {
            edges {
              node {
                fluid {
                  aspectRatio
                  base64
                  originalName
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
        }
      `}
      render={(data: GraphQLImages) => {
        const imageData = data.allImageSharp.edges.find(
          item => item.node.fluid.originalName === name,
        )

        // @ts-ignore
        // because property `originalName` does not exists on `FluidObject` type
        return imageData ? <Img fluid={imageData.node.fluid} /> : null
      }}
    />
  )
}
