import React, { useCallback, MouseEvent } from 'react'
import { StyledScrollDownArrow } from './styled'
import { Paragraph, Icon } from '@components'
import { isClient, scrollToElement } from '@utils'

type ScrollDownArrowProps = {
  href: string
  label: string
}

export const ScrollDownArrow: React.FC<ScrollDownArrowProps> = ({ href, label, ...rest }) => {
  const handleScroll = useCallback((event: MouseEvent) => {
    event.preventDefault()

    if (isClient) {
      const target = document.getElementById(href.substr(1))
      target && scrollToElement(target)
    }
  }, [])

  return (
    <StyledScrollDownArrow href={href} {...rest} onClick={handleScroll}>
      <Paragraph as="span" srOnly>
        {label}
      </Paragraph>
      <Icon name="ArrowDown" width={29} height={15} />
    </StyledScrollDownArrow>
  )
}
