import React, { useCallback } from 'react'
import { A } from '@bootstrap-styled/v4'
import { navigate } from 'gatsby'
import { StyledButton, ButtonProps } from './styled'

/**
 * Custom wrapper around Bootstrap button with benefits of Gatsby Link component.
 */
export const Button: React.FC<ButtonProps> = ({ to, children, type = 'button', ...rest }) => {
  const handleClick = useCallback((event: MouseEvent) => {
    event.preventDefault()

    to && navigate(to)
  }, [])

  if (to) {
    return (
      <StyledButton tag={A} href={to} onClick={handleClick} {...rest}>
        {children}
      </StyledButton>
    )
  }

  return (
    <StyledButton type={type} {...rest}>
      {children}
    </StyledButton>
  )
}
