import React, { useState, useEffect, useContext } from 'react'
import { Reference } from '@types'
import Glide from '@glidejs/glide'
import { Heading, Box } from '@components'
import { useLabels } from '@hooks'
import { ThemeContext } from 'styled-components'
import ArrowRightIcon from '@images/icons/arrow-right.svg'
import ArrowLeftIcon from '@images/icons/arrow-left.svg'

import '@glidejs/glide/dist/css/glide.core.css'
import '@glidejs/glide/dist/css/glide.theme.css'
import {
  ReferenceContent,
  GlideWrapper,
  GlideArrowButton,
  GlideBullets,
  GlideBullet,
} from './styled'

type ReferencesProps = {
  dataSource: Reference[]
}

export const References: React.FC<ReferencesProps> = ({ dataSource }) => {
  const [carousel] = useState(
    dataSource.length
      ? new Glide('.js-glide', {
          type: 'carousel',
          perView: 1,
        })
      : null,
  )
  const [activeSlide, setActiveSlide] = useState(0)

  const labels = useLabels()
  const theme = useContext(ThemeContext)

  useEffect(() => {
    if (carousel) {
      carousel.mount()

      carousel.on('run.after', () => {
        setActiveSlide(carousel.index)
      })
    }

    return () => carousel && carousel.destroy()
  }, [])

  return dataSource.length > 0 ? (
    <Box>
      <GlideWrapper className="glide js-glide">
        <div className="glide__track" data-glide-el="track">
          <div className="glide__slides">
            {dataSource.map(reference => (
              <div key={reference.id} className="glide__slide">
                <Heading variant="h3" textSize="xlarge" fontWeight="medium">
                  {reference.name}
                </Heading>
                <ReferenceContent dangerouslySetInnerHTML={{ __html: reference.content }} />
              </div>
            ))}
          </div>
        </div>
        {dataSource.length > 1 && (
          <>
            <div className="glide__arrows" data-glide-el="controls">
              <GlideArrowButton
                outline
                className="glide__arrow glide__arrow--left"
                data-glide-dir="<"
                aria-label={labels.previous}
              >
                <ArrowLeftIcon name="icon" width={7} height={14} fill={theme.colors.gray700} />
              </GlideArrowButton>
              <GlideArrowButton
                outline
                className="glide__arrow glide__arrow--right"
                data-glide-dir=">"
                aria-label={labels.next}
              >
                <ArrowRightIcon name="icon" width={7} height={14} fill={theme.colors.gray700} />
              </GlideArrowButton>
            </div>
            <GlideBullets data-glide-el="controls[nav]">
              {dataSource.map((item, idx) => (
                <GlideBullet
                  key={item.id}
                  data-glide-dir={`=${idx}`}
                  aria-label={`${labels.slideNo} ${idx + 1} ${
                    activeSlide === idx ? `(${labels.active})` : ''
                  }`}
                />
              ))}
            </GlideBullets>
          </>
        )}
      </GlideWrapper>
    </Box>
  ) : null
}
