import styled, { keyframes, css } from 'styled-components'

const loaderSize = 3

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`

export const LoaderBackdrop = styled.div<{
  isVisible: boolean
}>`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.black};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  ${({ theme }) => theme.transition('opacity')}
  ${({ isVisible }) =>
    isVisible
      ? css`
          opacity: 0.5;
          pointer-events: auto;
        `
      : css`
          opacity: 0;
          pointer-events: none;
        `}
`

export const LoaderWrapper = styled.div`
  width: ${loaderSize}em;
  height: ${loaderSize}em;
  position: absolute;
  z-index: 101;
  top: calc(50% - ${loaderSize / 2}em);
  left: calc(50% - ${loaderSize / 2}em);
  border: 3px solid ${({ theme }) => theme.colors.white};
  border-top-color: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
`
