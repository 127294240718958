import styled, { css } from 'styled-components'

type BackdropProps = {
  isVisible: boolean
}

export const Backdrop = styled.div<BackdropProps>`
  display: block;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.black};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 900;
  opacity: 0;
  pointer-events: none;
  ${({ theme }) => theme.transition('opacity')};
  transition-delay: ${({ theme }) => theme.transitionDurations.default};

  ${({ isVisible, theme }) =>
    isVisible &&
    css`
      ${theme.mediaQuery(theme.breakpoints.MEDIUM, 'max')} {
        pointer-events: auto;
        opacity: 0.5;
      }
    `}
`
