import React, { useState, useEffect } from 'react'
import { Header, Footer, Backdrop } from '@components'
import { useSiteMetadata } from '@hooks'
import { GlobalAppStyle } from '@theme'

const esc = {
  KEY: 'escape',
  KEY_CODE: 27,
}

type LayoutProps = {
  hideHeaderFooter?: boolean
}

export const Layout: React.FC<LayoutProps> = ({ children, hideHeaderFooter = false }) => {
  const [isMobileMenuVisible, setMobileMenuVisible] = useState(false)
  const { title } = useSiteMetadata()

  useEffect(() => {
    if (isMobileMenuVisible) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }

    // hide the navbar using ESC key
    const hideMobileMenu = (event: KeyboardEvent) => {
      if (
        (event.key !== undefined && event.key.toLowerCase() === esc.KEY) ||
        event.keyCode === esc.KEY_CODE
      ) {
        if (isMobileMenuVisible) {
          setMobileMenuVisible(false)
        }
      }
    }

    document.addEventListener('keydown', hideMobileMenu)

    return () => {
      document.removeEventListener('keydown', hideMobileMenu)
    }
  }, [isMobileMenuVisible])

  return (
    <>
      <GlobalAppStyle />
      {!hideHeaderFooter && (
        <Header
          siteTitle={title}
          isMobileMenuVisible={isMobileMenuVisible}
          setMobileMenuVisible={setMobileMenuVisible}
        />
      )}
      <main>{children}</main>
      {!hideHeaderFooter && <Footer siteTitle={title} />}
      <Backdrop isVisible={isMobileMenuVisible} onClick={() => setMobileMenuVisible(false)} />
    </>
  )
}
