import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import bg from '@images/bg-wall.png'

const navbarMobileWidth = '200px'

const activeNavLinkStyle = css`
  opacity: 1;
  transform: translateY(0);
`

const NavbarFlexStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Navbar = styled.div`
  ${NavbarFlexStyles}
`

export const NavbarHeader = styled.div`
  ${NavbarFlexStyles}
  flex-grow: 1;
`

export const Wrapper = styled.header`
  padding: ${({ theme }) => theme.spacer(2)} 0;
  background-image: url(${bg});
  background-attachment: fixed;
`

export const NavigationWrapper = styled.div<{
  isMobileMenuVisible: boolean
}>`
  ${({ theme }) => theme.mediaQuery(theme.breakpoints.MEDIUM, 'max')} {
    width: ${navbarMobileWidth};
    height: 100vh;
    min-height: -webkit-fill-available;
    position: fixed;
    background-color: ${({ theme }) => theme.colors.white};
    top: 0;
    right: 0;
    z-index: 1000;
    transform: translateX(${navbarMobileWidth});
    transition: transform 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);

    ${({ isMobileMenuVisible }) =>
      isMobileMenuVisible &&
      css`
        transform: translateX(0);
        overflow-y: auto;
      `}
  }
`

export const LogoLink = styled(Link)`
  display: block;
  line-height: 0;
`

export const Logo = styled.img`
  ${({ theme }) => css`
    ${theme.mediaQuery(theme.breakpoints.MEDIUM, 'max')} {
      width: 174px;
      height: 80px;
    }
  `}
`

export const NavLink = styled(Link)`
  display: block;
  padding-top: ${({ theme }) => theme.spacer(0.5)};
  padding-bottom: ${({ theme }) => theme.spacer(0.5)};
  text-decoration: none;
  position: relative;
  font-weight: ${({ theme }) => theme.fontWeight.medium};

  &.active {
    &::after {
      ${activeNavLinkStyle}
    }
  }

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.primary};
    position: absolute;
    left: 0;
    bottom: ${({ theme }) => theme.spacer(0.25)};
    z-index: 1;
    pointer-events: none;
    opacity: 0;
    transform: translateY(3px);
    transition: opacity ${({ theme }) => theme.transitionDurations.default} ease,
      transform ${({ theme }) => theme.transitionDurations.default} ease;
  }

  &:hover,
  &:focus {
    text-decoration: none;

    &::after {
      ${activeNavLinkStyle}
    }
  }

  ${({ theme }) => css`
    ${theme.mediaQuery(theme.breakpoints.MEDIUM, 'max')} {
      padding: ${theme.spacer(0.75)} ${theme.spacer(1)};

      &::after {
        width: 25%;
        left: ${theme.spacer(1)};
      }
    }
  `}
`

export const NavItem = styled.li`
  ${({ theme }) => css`
    ${theme.mediaQuery(theme.breakpoints.MEDIUM, 'min')} {
      margin-left: ${theme.spacer(1.25)};
      margin-right: ${theme.spacer(1.25)};
    }
  `}
`

export const Navigation = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;

  ${({ theme }) => theme.mediaQuery(theme.breakpoints.MEDIUM, 'max')} {
    display: block !important;
    margin-top: 100px;
  }
`
