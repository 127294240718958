import React from 'react'

import Logo from '@images/icons/logo.svg'
import ArrowRight from '@images/icons/arrow-right.svg'
import ArrowLeft from '@images/icons/arrow-left.svg'
import Close from '@images/icons/close.svg'
import Ultrasonic from '@images/icons/ultrasonic.svg'
import BabyTeeth from '@images/icons/baby-teeth.svg'
import HealthyTooth from '@images/icons/healthy-tooth.svg'
import BadTooth from '@images/icons/bad-tooth.svg'
import ToothCare from '@images/icons/tooth-care.svg'
import Clock from '@images/icons/clock.svg'
import ArrowDown from '@images/icons/arrow-down.svg'
import Facebook from '@images/icons/facebook.svg'
import Instagram from '@images/icons/instagram.svg'
import Adult from '@images/icons/adult.svg'
import Child from '@images/icons/child.svg'

const icons: { [key: string]: any } = {
  Logo: Logo,
  ArrowRight: ArrowRight,
  ArrowLeft: ArrowLeft,
  Close: Close,
  Ultrasonic: Ultrasonic,
  BabyTeeth: BabyTeeth,
  HealthyTooth: HealthyTooth,
  BadTooth: BadTooth,
  ToothCare: ToothCare,
  Clock: Clock,
  ArrowDown: ArrowDown,
  Facebook: Facebook,
  Instagram: Instagram,
  Adult: Adult,
  Child: Child,
}

type IconProps = {
  name: keyof typeof icons
  size?: number
  width?: number
  height?: number
  className?: string
  color?: string
}

export const Icon: React.FC<IconProps> = ({
  name,
  size,
  width,
  height,
  className,
  color = '#000000',
  ...rest
}) => {
  const Component = icons[name]

  return (
    <Component
      width={size || width}
      height={size || height}
      aria-hidden="true"
      focusable={false}
      className={`icon ${className ? className : ''}`}
      fill={color}
      {...rest}
    />
  )
}
