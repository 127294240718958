import React from 'react'
import styled from 'styled-components'
import { isClient } from '@utils'

type CollapseProps = {
  /** Boolean describing if the content is collapsed or not. */
  isOpen: boolean
  id: string
}

const StyledCollapse = styled.div<CollapseProps>`
  height: 0;
  overflow: hidden;
  ${({ theme }) => theme.transition('height')}
  background-color: rgba(255, 255, 255, 0.6);

  > div {
    padding: ${({ theme }) => theme.spacer(1)};

    > *:first-child {
      margin-top: 0;
    }
    *:last-child {
      margin-bottom: 0;
    }
  }
`

// inspired by https://css-tricks.com/using-css-transitions-auto-dimensions/
export const CollapseContent: React.FC<CollapseProps> = ({ isOpen, children, ...rest }) => {
  const containerEl = React.useRef(isClient ? document.createElement('div') : null)

  function expandContainer(container: HTMLDivElement): void {
    const height = container.scrollHeight

    container.style.height = `${height}px`

    container.addEventListener('transitionend', function transitionEndListener() {
      container.removeEventListener('transitionend', transitionEndListener)
      container.style.height = 'auto'
    })
  }

  function collapseContainer(container: HTMLDivElement): void {
    const height = container.scrollHeight
    const containerTransition = container.style.transition

    container.style.transition = ''

    requestAnimationFrame(() => {
      container.style.height = `${height}px`
      container.style.transition = containerTransition

      requestAnimationFrame(() => {
        container.style.height = '0px'
      })
    })
  }

  React.useEffect(() => {
    if (isOpen) {
      containerEl.current && expandContainer(containerEl.current)
    } else {
      containerEl.current && collapseContainer(containerEl.current)
    }
  }, [isOpen])

  return (
    <StyledCollapse isOpen={isOpen} ref={containerEl} {...rest}>
      {children}
    </StyledCollapse>
  )
}
