import styled from 'styled-components'
import { StyledHeading } from '@components'

export const StyledCollapseHeader = styled.div<{
  collapsed: boolean
}>`
  background-color: ${({ collapsed, theme }) =>
    collapsed ? theme.colors.white : theme.colors.primary};

  ${StyledHeading} {
    margin: 0;
    &::before {
      display: none;
    }

    a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-decoration: none;
      padding: ${({ theme }) => theme.spacer(1)};
      ${({ theme }) => theme.transition(['background-color', 'color'])}

      &:hover,
      &:focus {
        color: currentColor;
        background-color: ${({ theme }) => theme.colors.primary};

        .icon {
          fill: currentColor;
        }
      }

      > span {
        flex-basis: 90%;
      }

      .icon {
        transform: rotateZ(0);
        transform-origin: center center;
        ${({ theme }) => theme.transition(['transform', 'fill'])}

        &.opened {
          transform: rotateZ(180deg);
          fill: currentColor;
        }
      }
    }
  }
`

export const CollapseWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacer(1.5)};
  box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  overflow: hidden;
`
