import { useStaticQuery, graphql } from 'gatsby'

type PriceList = {
  id: number
  title: string
  content: string
  duration: string
  price: string
}

export const usePriceList = (): PriceList[] => {
  const data = useStaticQuery(graphql`
    query PricesQuery {
      wpgraphql {
        prices(where: { orderby: { order: ASC, field: DATE } }) {
          nodes {
            content
            title
            databaseId
            acf {
              duration
              price
            }
          }
        }
      }
    }
  `)

  return data.wpgraphql.prices.nodes.map((item: any) => ({
    id: item.databaseId,
    title: item.title,
    content: item.content,
    duration: item.acf.duration,
    price: item.acf.price,
  }))
}
