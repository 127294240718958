import React, { MouseEvent, useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { useLabels } from '@hooks'
import CloseIcon from '@images/icons/close.svg'

type ModalCloseProps = {
  /** Function that will be run when clicked on the close button. */
  onClick: (event: MouseEvent) => void
}

const StyledModalClose = styled.button`
  cursor: pointer;
  line-height: 0;
  border: 0;
  padding: ${({ theme }) => theme.spacer(1)};
  margin: 0;
  background: none;
  box-shadow: none;
  position: absolute;
  top: 0;
  right: 0;
  ${({ theme }) => theme.transition('opacity')}

  &:focus,
  &:hover {
    opacity: 0.7;
  }
`

export const ModalClose: React.FC<ModalCloseProps> = ({ onClick }) => {
  const labels = useLabels()
  const { colors } = useContext(ThemeContext)

  return (
    <StyledModalClose type="button" onClick={onClick} aria-label={labels.close}>
      <CloseIcon width={20} height={20} fill={colors.gray700} />
    </StyledModalClose>
  )
}
