import React from 'react'
import { useStaticQuery } from 'gatsby'
import { Container } from '@bootstrap-styled/v4'
import { graphql } from 'gatsby'
import { Hamburger } from '@components'
import logo from '@images/logo.svg'
import { MenuItem } from '@types'
import {
  Wrapper,
  Navbar,
  LogoLink,
  Logo,
  NavLink,
  NavItem,
  NavbarHeader,
  NavigationWrapper,
  Navigation,
} from './styled'

import 'hamburgers/dist/hamburgers.min.css'

type HeaderProps = {
  siteTitle: string
  isMobileMenuVisible: boolean
  setMobileMenuVisible: React.Dispatch<React.SetStateAction<boolean>>
}

export const Header: React.FC<HeaderProps> = ({
  siteTitle,
  isMobileMenuVisible,
  setMobileMenuVisible,
}) => {
  const {
    wpgraphql: { menus },
  } = useStaticQuery(graphql`
    query {
      wpgraphql {
        menus(where: { slug: "main-menu" }) {
          edges {
            node {
              menuItems {
                edges {
                  node {
                    id
                    url
                    label
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const items: MenuItem[] = menus.edges[0].node.menuItems.edges.map(({ node }: any) => ({
    ...node,
    url: node.url.replace(/^http[s]?:\/\/[A-Z0-9a-z\.]*/, ''), // remove protocol + hostname
  }))

  return (
    <Wrapper>
      <Container>
        <Navbar>
          <NavbarHeader>
            <LogoLink to="/">
              <Logo src={logo} alt={siteTitle} width="261" height="120" />
            </LogoLink>
            <Hamburger
              isActive={isMobileMenuVisible}
              onClick={() => setMobileMenuVisible(prevState => !prevState)}
            />
          </NavbarHeader>
          <NavigationWrapper isMobileMenuVisible={isMobileMenuVisible}>
            <nav id="nav">
              <Navigation>
                {items.map(item => (
                  <NavItem key={item.id}>
                    <NavLink to={item.url} activeClassName="active">
                      {item.label}
                    </NavLink>
                  </NavItem>
                ))}
              </Navigation>
            </nav>
          </NavigationWrapper>
        </Navbar>
      </Container>
    </Wrapper>
  )
}
