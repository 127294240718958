import styled, { css } from 'styled-components'
import { Button as BSButton } from '@bootstrap-styled/v4'

export type ButtonProps = {
  to?: string
  color?:
    | 'link'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'info'
    | 'warning'
    | 'danger'
    | 'white'
    | 'light'
    | 'dark'
  size?: 'sm' | 'lg'
  block?: boolean
  outline?: boolean
  /** Button will have look & feel as a standard anchor. */
  linkui?: boolean | number
  type?: 'button' | 'submit' | 'reset'
  vCenter?: boolean | number
  onClick?: () => void
  className?: string
  disabled?: boolean
}

export const StyledButton = styled(BSButton)<ButtonProps>`
  border: 0;
  padding: 0;
  background-color: transparent;
  ${({ linkui, theme }) =>
    linkui &&
    css`
      color: ${theme.colors.gray700} !important;
      border: 0 !important;
      padding: 0 !important;
      background-color: transparent !important;
      text-decoration: underline;

      &:hover,
      &:focus {
        text-decoration: none !important;
      }
    `}
  ${({ vCenter, theme }) =>
    vCenter &&
    css`
      display: inline-flex !important;
      align-items: center;

      > *:not(:last-child) {
        margin-right: ${theme.spacer(0.5)};
      }
    `}

  .icon {
    ${({ theme }) => theme.transition('transform')}
  }

  &:hover,
  &:focus {
    .icon {
      transform: translateX(3px);
    }
  }

  &:focus {
    outline: 1px dotted !important;
    outline: 5px auto -webkit-focus-ring-color !important;
  }
`
