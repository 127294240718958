import { useStaticQuery, graphql } from 'gatsby'

type Result = {
  title: string
  content: string
  imageBefore: {
    sourceUrl: string
  }
  imageAfter: {
    sourceUrl: string
  }
}

export const useResults = (): Result[] => {
  const data = useStaticQuery(graphql`
    query ResultsQuery {
      wpgraphql {
        results(where: { orderby: { order: ASC, field: DATE } }, first: 100) {
          nodes {
            content
            title
            acf {
              imageBefore {
                sourceUrl
              }
              imageAfter {
                sourceUrl
              }
            }
          }
        }
      }
    }
  `)

  return data.wpgraphql.results.nodes.map((item: any) => ({
    title: item.title,
    content: item.content,
    imageBefore: item.acf.imageBefore,
    imageAfter: item.acf.imageAfter,
  }))
}
