import React from 'react'
import styled, { css } from 'styled-components'
import { theme } from '@theme'
import { TextAlignment } from '@types'

type TypographyProps = {
  textSize?: keyof typeof theme.fontSizes
  fontWeight?: keyof typeof theme.fontWeight
  color?: string
  srOnly?: boolean
  className?: string
}

export type HeadingProps = {
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  align?: TextAlignment
  id?: string
}

const BaseStyles = css<TypographyProps>`
  ${({ textSize, theme }) =>
    textSize &&
    css`
      font-size: ${theme.fontSizes[textSize]};
    `}

  ${({ fontWeight, theme }) =>
    fontWeight &&
    css`
      font-weight: ${theme.fontWeight[fontWeight]};
    `}

  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
  
  ${({ srOnly }) =>
    srOnly &&
    css`
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border: 0;
    `}
`

export const HeadingOverLineStyle = css<HeadingProps>`
  position: relative;
  &::before {
    content: '';
    display: block;
    width: 65px;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.primary};
    position: absolute;
    top: ${({ theme }) => theme.spacer(-0.5)};
    left: ${({ align }) => {
      switch (align) {
        case TextAlignment.LEFT:
          return 0

        case TextAlignment.CENTER:
          return '50%'

        case TextAlignment.RIGHT:
          return 'auto'

        default:
          return 0
      }
    }};
    ${({ align }) =>
      align &&
      align === TextAlignment.CENTER &&
      css`
        transform: translateX(-50%);
      `}
  }
`

export const Paragraph = styled.p<TypographyProps>`
  ${BaseStyles}
`

export const StyledHeading = styled.h1<TypographyProps | HeadingProps>`
  ${BaseStyles}
  ${HeadingOverLineStyle}
`

export const Heading: React.FC<TypographyProps & HeadingProps> = ({
  variant,
  children,
  ...rest
}) => (
  <StyledHeading as={variant} {...rest}>
    {children}
  </StyledHeading>
)

export const HeroHeading = styled(StyledHeading)`
  ${({ theme }) => theme.rfs(50)}
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  margin-bottom: ${({ theme }) => theme.spacer(1)};
  text-align: ${({ align }) => (align ? align : TextAlignment.LEFT)};
  ${HeadingOverLineStyle}
`

export const VCenter = styled.span.attrs<TypographyProps>({
  className: 'd-inline-flex align-items-center',
})`
  > *:not(:last-child) {
    margin-right: ${theme.spacer(0.5)};
  }
`
