import { useStaticQuery, graphql } from 'gatsby'

type Labels = {
  showNavigation: string
  hideNavigation: string
  booking: string
  moreInfo: string
  close: string
  reference: string
  previous: string
  next: string
  slideNo: string
  active: string
  whyMe: string
  contact: string
  officeHours: string
  email: string
  phoneNumber: string
  duration: string
  notFound: string
  notFoundDescription: string
  backToHomepage: string
  fullName: string
  message: string
  submit: string
  formSentMessage: string
  formErrorMessage: string
  contactFormHeading: string
  taxId: string
  vatId: string
  mandatoryNote: string
  formSentTitle: string
  recaptchaRequired: string
  map: string
  createdBy: string
  questionnaire: string
  questionnaireDescription: string
  questionnaireAdult: string
  questionnaireChild: string
  before: string
  after: string
}

export const useLabels = (): Labels => {
  const {
    site: { siteMetadata },
  } = useStaticQuery(graphql`
    query LabelsQuery {
      site {
        siteMetadata {
          labels {
            showNavigation
            hideNavigation
            booking
            moreInfo
            close
            reference
            previous
            next
            slideNo
            active
            whyMe
            contact
            officeHours
            email
            phoneNumber
            duration
            notFound
            notFoundDescription
            backToHomepage
            fullName
            message
            submit
            formSentMessage
            formErrorMessage
            contactFormHeading
            taxId
            vatId
            mandatoryNote
            formSentTitle
            recaptchaRequired
            map
            createdBy
            questionnaire
            questionnaireDescription
            questionnaireAdult
            questionnaireChild
            before
            after
          }
        }
      }
    }
  `)

  return {
    showNavigation: siteMetadata.labels.showNavigation,
    hideNavigation: siteMetadata.labels.hideNavigation,
    booking: siteMetadata.labels.booking,
    moreInfo: siteMetadata.labels.moreInfo,
    close: siteMetadata.labels.close,
    reference: siteMetadata.labels.reference,
    previous: siteMetadata.labels.previous,
    next: siteMetadata.labels.next,
    slideNo: siteMetadata.labels.slideNo,
    active: siteMetadata.labels.active,
    whyMe: siteMetadata.labels.whyMe,
    contact: siteMetadata.labels.contact,
    officeHours: siteMetadata.labels.officeHours,
    email: siteMetadata.labels.email,
    phoneNumber: siteMetadata.labels.phoneNumber,
    duration: siteMetadata.labels.duration,
    notFound: siteMetadata.labels.notFound,
    notFoundDescription: siteMetadata.labels.notFoundDescription,
    backToHomepage: siteMetadata.labels.backToHomepage,
    fullName: siteMetadata.labels.fullName,
    message: siteMetadata.labels.message,
    submit: siteMetadata.labels.submit,
    formSentMessage: siteMetadata.labels.formSentMessage,
    formErrorMessage: siteMetadata.labels.formErrorMessage,
    contactFormHeading: siteMetadata.labels.contactFormHeading,
    taxId: siteMetadata.labels.taxId,
    vatId: siteMetadata.labels.vatId,
    mandatoryNote: siteMetadata.labels.mandatoryNote,
    formSentTitle: siteMetadata.labels.formSentTitle,
    recaptchaRequired: siteMetadata.labels.recaptchaRequired,
    map: siteMetadata.labels.map,
    createdBy: siteMetadata.labels.createdBy,
    questionnaire: siteMetadata.labels.questionnaire,
    questionnaireDescription: siteMetadata.labels.questionnaireDescription,
    questionnaireAdult: siteMetadata.labels.questionnaireAdult,
    questionnaireChild: siteMetadata.labels.questionnaireChild,
    before: siteMetadata.labels.before,
    after: siteMetadata.labels.after,
  }
}
