import React from 'react'
import styled, { css } from 'styled-components'

export type BoxProps = {
  overflow?: 'vertical' | 'horizontal'
  className?: string
  background?: string
  smallPadding?: boolean
  ref?: React.Ref<HTMLDivElement>
}

export const StyledBox = styled.div<BoxProps>`
  padding: ${({ theme, smallPadding }) => (smallPadding ? theme.spacer(1) : theme.spacer(2))};
  background-color: ${({ background, theme }) => (background ? background : theme.colors.primary)};
  box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.15);
  position: relative;
  border-radius: ${({ theme }) => theme.borderRadius.large};

  ${({ overflow }) =>
    overflow &&
    overflow === 'vertical' &&
    css`
      margin: ${({ theme }) => theme.spacer(-4)} 0;
    `}

  ${({ theme }) => theme.mediaQuery(theme.breakpoints.XSMALL, 'max')} {
    padding: ${({ theme, smallPadding }) => !smallPadding && theme.spacer(1.5)};
  }

  a:not(.btn) {
    &:focus,
    &:hover {
      color: ${({ background, theme }) => (background ? theme.colors.primary : theme.colors.white)};
    }
  }
`
